import React from "react";
import style from "./style.module.css";

export default function Testings() {
  return (
    <section>
      <div className="sectionTitle">
        <h2>Testing</h2>
      </div>
      <div className={style.wrapper}>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
        <div className={style.card}>
          <h3>Site name</h3>
          <span>
            <a href="#">Website link</a>{" "}
          </span>
          <hr className={style.hrThin} />
          <p>Description about website</p>
        </div>
      </div>
    </section>
  );
}
