import "./css/App.css";
import Typography from "./components/Typography/Typography";
import Design from "./components/Design/Design";
import Developments from "./components/Developments";
import Testings from "./components/Testings";

function App() {
  return (
    <div className="App">
      <header className="App-header title"></header>
      <div className="title">
        <h1 className=""> Developments Tools </h1>
      </div>
      <Design />
      <Typography />
      <Developments />
      <Testings />
    </div>
  );
}

export default App;
