import React from "react";
import style from "../style.module.css";
import data from "./data.json";

export default function Design() {
  return (
    <section>
      <div className="sectionTitle">
        <h2>Design</h2>
      </div>
      <div className={style.wrapper}>
        {data.map((item) => {
          console.log(item.title);
          return (
            <div className={style.card} key={item.id}>
              <h3>{item.title}</h3>
              <hr className={style.hrThin} />
              <p>{item.description}</p>
              <div className={style.number}>{item.id}</div>
              <a href={item.link} target="_blank">
                <button> Website link </button>
              </a>
              <img src={item.src} alt="website logo" />
            </div>
          );
        })}
      </div>
    </section>
  );
}
